var gallerySwiper = new Swiper('.gallery-placer .swiper-container', {
  spaceBetween: 30,
  effect: 'fade',
  autoplay: {
    delay: 3000,
  },
  fadeEffect: {
    crossFade: false,
  },
});

if(!isMobile){
  $(".block-category").hover(
    function() {
      gallerySwiper.autoplay.stop();
      gallerySwiper.slideTo($(this).index());
    }, function() {
    }
  );
  gallerySwiper.autoplay.stop();
}else{
  gallerySwiper.autoplay.start();
}

var tl = gsap.timeline();

if($(".home-section-slider:visible").length){
  tl.from(".home-section-slider .block-category", {
    duration:1,
    delay:1,
    opacity:0,
    y:20,
    stagger:.4
  });
  tl.from(".home-section-slider .title-font", {
    duration:1.5,
    delay:.6,
    opacity:0,
    y:40,
    stagger:.4
  })
  tl.eventCallback("onComplete", function(){
    if(!isMobile){
      gallerySwiper.autoplay.start();
    }
  });
  tl.pause();
}else if($(".mobile-homepage-content").length){
  tl.from(".welcome-section .title-font", {
    duration:1.5,
    delay:2,
    opacity:0,
    y:50,
    stagger:.6
  });
  tl.from(".welcome-section .ps, .welcome-section .learn-more", {
    duration:1,
    opacity:0,
  });
  tl.from(".welcome-section .line-extend", {
    duration:1.6,
    delay:1,
    height:0,
    opacity:0,
  });
}

$.each([
    $(".main-service-set .texting-wrap .tag"),
    $(".main-service-set .texting-wrap h6"),
    $(".main-service-set .texting-wrap .border-btn"),
], function(i, v){
    $(this).attr({
        'data-aos': "gentle-up",
        'data-aos-duration': 1500,
        'data-aos-delay': i*300,
        'data-aos-anchor-placement': 'top-bottom',
    });
})


$(window).on('load', function(){
  tl.play();
})